
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */

html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0;
    background-color: #121212;
    color: #fff;
}

article,
aside,
footer,
header,
nav,
section {
    display: block
}

h1 {
    font-size: 2em;
    margin: .67em 0
}

figcaption,
figure {
    display: block
}

figure {
    margin: 1em 40px
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

main {
    display: block
}

pre {
    /* font-family: monospace, monospace; */
    font-size: 1em
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,
strong {
    font-weight: inherit;
    font-weight: bolder
}

code,
kbd,
samp {
    /* font-family: monospace, monospace; */
    font-size: 1em;
}

dfn {
    font-style: italic
}

small {
    /* font-size: 80% */
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

audio,
video {
    display: inline-block
}

audio:not([controls]) {
    display: none;
    height: 0
}

img {
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

button,
input,
optgroup,
select,
textarea {
    /* font-family: sans-serif; */
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0
}

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText
}

input {
    overflow: visible
}

[type=checkbox],
[type=radio] {
    box-sizing: border-box;
    padding: 0
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

fieldset {
    padding: .35em .75em .625em
}

legend {
    box-sizing: border-box;
    display: table;
    max-width: 100%;
    padding: 0;
    color: inherit;
    white-space: normal
}

progress {
    display: inline-block;
    vertical-align: baseline
}

textarea {
    overflow: auto
}

details {
    display: block
}

summary {
    display: list-item
}

menu {
    display: block
}

canvas {
    display: inline-block
}

[hidden],
template {
    display: none
}

body,
html {
    /* color: rgba(0, 0, 0, .75); */
    font-size: 1.0em;
    /* font-family: Lato, Helvetica Neue, Helvetica, sans-serif; */
    font-variant-ligatures: common-ligatures;
    line-height: 1.67;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.app--dark .layout__panel--editor,
.app--dark .layout__panel--preview {
    color: hsla(0, 0%, 100%, .75)
}

blockquote,
dl,
ol,
p,
pre,
ul {
    margin: 1.2em 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 1.4em 0;
    line-height: 1.33;
    font-weight: 700;
}

h2:after {
    content: "";
    display: block;
    position: relative;
    top: .45em;
    border-bottom: 1px solid hsla(0, 0%, 50%, .33)
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin: 0
}

dt {
    font-weight: 700
}

/* 기본 링크 스타일 */
a {
    color: #1e90ff; /* 밝은 파란색으로 링크 색상 변경 */
    text-decoration-skip: auto; /* 텍스트 장식 스킵 */
}

/* 링크 호버 및 포커스 상태 스타일 */
a:hover,
a:focus {
    color: #ffffff; /* 호버 및 포커스 시 링크 색상 변경 (흰색) */
    text-decoration: underline; /* 텍스트에 밑줄 추가 */
}


code,
pre,
samp {
    /* font-family: Consolas,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Courier New,"맑은고딕",'Malgun Gothic'; */
    font-family: "SF Mono", "Consolas", "Menlo";
    font-size: .85em
}

code *,
pre *,
samp * {
    font-size: inherit
}


/* blockquote {
    color: rgba(0, 0, 0, .7);
    padding-left: 1.5em;
    border-left: 5px solid rgba(0, 0, 0, .1)
} */

blockquote {
    background-color: #2e2e2e; /* or any of the other suggested background colors */
    color: #f5f5f5; /* or any of the other suggested text colors */
    border-left: 5px solid #4a4a4a; /* 좀 더 강조를 위해 다른 색상 사용 가능 */
    padding: 15px;
}

blockquote ul {
    margin: 0; /* 기본 margin을 없앰 */
    padding: 0; /* 필요에 따라 padding도 없앨 수 있음 */
}

.app--dark .layout__panel--editor blockquote,
.app--dark .layout__panel--preview blockquote {
    color: hsla(0, 0%, 100%, .4);
    border-left-color: hsla(0, 0%, 100%, .1)
}

code {
    background-color: #1e1e1e; /* or any of the other suggested background colors */
    color: #a8ff60; /* or any of the other suggested text colors */
}


hr {
    border: 0;
    border-top: 1px solid hsla(0, 0%, 50%, .33);
    margin: 2em 0
}

pre > code {
    /* 다크모드에 맞는 어두운 배경색 */
    background-color: #2e2e2e;
    
    /* 밝은 텍스트 색상 */
    color: #e0e0e0;
    
    display: block;
    padding: 1.0em;
    -webkit-text-size-adjust: none;
    overflow-x: auto;
    white-space: pre-wrap;
}


.toc ul {
    list-style-type: none;
    /* padding-left: 20px */
}

table {
    margin: 20px 0;
    background-color: #2e2e2e; /* 다크모드 배경색 */
    border-collapse: collapse;
    border-spacing: 0;
    border: solid 1px #555; /* 다크모드에 맞는 테두리 색상 */
}

table tr th {
    background-color: #3e3e3e; /* 테이블 헤더의 배경색 (다크모드용) */
    color: #e0e0e0; /* 헤더 텍스트 색상 */
}

td,
th {
    border-right: 1px solid #555; /* 다크모드에 맞는 셀 사이의 테두리 색상 */
    padding: 8px 12px;
    color: #e0e0e0; /* 일반 셀 텍스트 색상 */
}

td:last-child,
th:last-child {
    border-right: 0; /* 마지막 셀의 테두리는 제거 */
}

td {
    border-top: 1px solid #555; /* 셀의 상단 테두리 색상 */
}


mark {
    background-color: #444444; /* 다크모드 배경에 어울리는 어두운 강조색 */
    color: #ffffff; /* 밝은 텍스트 색상 */
    padding-left: 0;
    padding-right: 0;
}

mark.add {
    background-color: #556b2f; /* 다크모드에 적합한 어두운 올리브 색상 */
    color: #ffffff; /* 텍스트 색상 */
}

mark.del {
    background-color: #333333; /* 다크모드 배경에 어울리는 어두운 회색 */
    color: #ffffff; /* 밝은 텍스트 색상 */
}


kbd {
    /* font-family: Lato, Helvetica Neue, Helvetica, sans-serif; */
    background-color: #fff;
    border: 1px solid rgba(63, 63, 63, .25);
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(63, 63, 63, .25);
    color: #333;
    display: inline-block;
    font-size: .8em;
    margin: 0 .1em;
    padding: .1em .6em;
    white-space: nowrap
}

abbr[title] {
    border-bottom: 1px dotted #777;
    cursor: help
}

img {
    max-width: 100%
}

.task-list-item {
    list-style-type: none
}

.task-list-item-checkbox {
    margin: 0 .2em 0 -1.3em
}

/*
.footnote {
    font-size: .8em;
    position: relative;
    top: -.25em;
    vertical-align: top
}
*/

.page-break-after {
    page-break-after: always
}

.abc-notation-block {
    overflow-x: auto!important
}

.stackedit__html {
    margin-bottom: 180px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 750px
}

.stackedit__toc ul {
    padding: 0
}

.stackedit__toc ul a {
    margin: .5rem 0;
    padding: .5rem 1rem
}

.stackedit__toc ul ul {
    color: #888;
    font-size: .9em
}

.stackedit__toc ul ul a {
    margin: 0;
    padding: .1rem 1rem
}

.stackedit__toc li {
    display: block
}

.stackedit__toc a {
    display: block;
    color: inherit;
    text-decoration: none
}

.stackedit__toc a:active,
.stackedit__toc a:focus,
.stackedit__toc a:hover {
    background-color: rgba(0, 0, 0, .075);
    border-radius: 3px
}

.stackedit__left {
    position: fixed;
    display: none;
    width: 250px;
    height: 100%;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none
}

@media (min-width:1060px) {
    .stackedit__left {
        display: block
    }
}

.stackedit__right {
    position: absolute;
    right: 0;
    top: 0;
    left: 0
}

@media (min-width:1060px) {
    .stackedit__right {
        left: 250px
    }
}

.stackedit--pdf blockquote {
    border-left-color: #ececec
}

.stackedit--pdf .stackedit__html {
    padding-left: 0;
    padding-right: 0;
    max-width: none
}

h1 {
  font-size: 1.5em;
  }

h2 {
  font-size: 1.4em;
  }

h3 {
  font-size: 1.3em; }

h4 {
  font-size: 1.2em; }

h5 {
  font-size: 1.1em; }

h6 {
  color: #777777;
  font-size: 1.0em; }


.page-content fieldset {
    border: solid 2px #555; /* 다크모드에 맞는 어두운 테두리 색상 */
    padding: 10px 20px;
    background: #2e2e2e; /* 어두운 배경색 */
    margin: 20px 0;
}

.page-content legend {
    width: auto;
    border: none;
    margin: 0;
    padding: 0 5px;
    font-size: 14px;
    font-weight: bold;
    color: #cccccc; /* 다크모드에 적합한 밝은 텍스트 색상 */
}


/* 문단 스타일 */
.wp-content p {
    margin: 5px 0;
}

/* 위키 제목 링크 스타일 */
a.wiki_title {
    color: #ff6f61; /* 밝은 주홍색으로 변경 */
    text-decoration: none;
    font-size: 16px;
}

/* 내부 링크 스타일 */
a.inner_link {
    color: #ff6f61; /* 위키 제목 링크와 동일한 색상 */
    text-decoration: none;
}

/* 링크 호버 스타일 */
a.wiki_title:hover, 
a.inner_link:hover {
    text-decoration: underline;
}

/* 검색 결과 스타일 */
#search-results {
    width: 100%;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background-color: #222; /* 다크모드 배경색 */
    border-top: none;
    border-radius: 0; /* 모서리의 radius를 0으로 설정 */
    font-weight: bold;
    color: #e0e0e0; /* 다크모드 텍스트 색상 */
}

/* 검색 결과 항목 스타일 */
#search-results li {
    margin: 0;
    padding: 5px 30px;
    color: #e0e0e0; /* 항목 텍스트 색상 */
}

/* 결과 항목 스타일 */
.result-item {
    white-space: normal;
    padding: 0 15px;
    color: #e0e0e0; /* 결과 항목 텍스트 색상 */
}

/* 드롭다운 메뉴 활성 상태 스타일 */
.dropdown-menu .active {
    background-color: #444; /* 다크 배경색 */
    outline: none;
    color: #ffffff; /* 밝은 텍스트 색상 */
}

/* 위키 링크 스타일 */
a.wiki_link {
    color: #ff6f61; /* 위키 제목 링크와 동일한 색상 */
    text-decoration: none;
}

/* 위키 링크 호버 스타일 */
a.wiki_link:hover {
    text-decoration: underline;
}

/* 팝오버 스타일 */
.popover {
    max-width: 500px; /* 최대 너비 */
    background-color: #333; /* 다크모드 배경색 */
    color: #e0e0e0; /* 텍스트 색상 */
    border: 1px solid #555; /* 테두리 색상 */
}

/* 팝오버 화살표 스타일 */
.popover .popover-arrow {
    border-top-color: #333; /* 화살표 색상 */
}

/* 팝오버 화살표 스타일 (헤더와 바디 색상에 맞추기) */
.popover .popover-arrow::before {
    border-top-color: #333; /* 화살표 색상 */
}


/* 추가된 것들 */
.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #121212; /* 어두운 배경색 */
    border-radius: 4px;
    color: #f0f0f0; /* 밝은 텍스트 색상 */
}

/* 기본 버튼 스타일 */
.btn-default {
    color: #e0e0e0; /* 텍스트 색상 */
    background-color: #333; /* 배경색 */
    border-color: #555; /* 테두리 색상 */
}

/* 버튼 호버 시 스타일 */
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active {
    color: #ffffff; /* 호버 시 텍스트 색상 */
    background-color: #555; /* 호버 시 배경색 */
    border-color: #777; /* 호버 시 테두리 색상 */
}

/* 모달 배경색 및 테두리 색상 조정 */
.modal-content {
    background-color: #333; /* 다크모드 배경색 (어두운 회색) */
    color: #e0e0e0; /* 다크모드에서 잘 보이는 밝은 회색 텍스트 색상 */
}

/* 모달 헤더 배경색 및 텍스트 색상 조정 */
.modal-header {
    background-color: #444; /* 모달 헤더 배경색 */
    color: #e0e0e0; /* 헤더 텍스트 색상 */
}

/* 모달 바디 배경색 및 텍스트 색상 조정 */
.modal-body {
    color: #e0e0e0; /* 바디 텍스트 색상 */
}

/* 모달 푸터 배경색 및 텍스트 색상 조정 */
.modal-footer {
    background-color: #444; /* 모달 푸터 배경색 */
    color: #e0e0e0; /* 푸터 텍스트 색상 */
}

/* 모달 창의 배경색 조정 (모달이 열릴 때 어두운 배경을 추가) */
.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.8); /* 어두운 반투명 배경색 */
}


/* 팝오버 컨테이너의 배경색과 텍스트 색상 조정 */
.popover {
    background-color: #333; /* 다크모드 배경색 */
    color: #e0e0e0; /* 다크모드에서 잘 보이는 밝은 회색 텍스트 색상 */
    border: 1px solid #555; /* 경계 색상 */
}

/* 팝오버 헤더의 배경색과 텍스트 색상 조정 */
.popover-title {
    background-color: #444; /* 헤더 배경색 */
    color: #e0e0e0; /* 헤더 텍스트 색상 */
}

/* 팝오버 바디의 텍스트 색상 조정 */
.popover-content {
    color: #e0e0e0; /* 바디 텍스트 색상 */
}

/* 팝오버의 화살표 색상 조정 */
.popover .popover-arrow {
    border-top-color: #333; /* 화살표 색상 (상단 화살표) */
}

/* 팝오버의 화살표 색상 조정 (헤더와 바디 색상에 맞추기) */
.popover .popover-arrow::before {
    border-top-color: #333; /* 화살표 색상 */
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #e0e0e0; /* 다크모드에서 잘 보이는 밝은 회색 텍스트 색상 */
    background-color: #333; /* 다크모드 배경색 */
    background-image: none;
    border: 1px solid #555; /* 다크모드에 맞는 테두리 색상 */
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.2); /* 그림자 효과를 강조하여 더 뚜렷하게 */
    box-shadow: inset 0 1px 1px rgba(0,0,0,.2); /* 그림자 효과를 강조하여 더 뚜렷하게 */
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
    border-color: #80bdff; /* 포커스 시 테두리 색상 변경 */
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(38, 143, 255, 0.5);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(38, 143, 255, 0.5);
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #ffffff; /* 다크모드에 맞는 밝은 텍스트 색상 */
    text-shadow: 0 1px 0 #000; /* 텍스트 그림자: 어두운 배경에 어울리는 색상 */
    filter: alpha(opacity=40); /* IE8 및 이전 버전을 위한 필터 */
    opacity: .4; /* 기본 투명도 조정 */
}


.close:focus, .close:hover {
    color: #ffffff; /* 밝은 텍스트 색상 */
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=80); /* IE8 및 이전 버전을 위한 필터 */
    opacity: .8; /* 투명도 조정 */
}

pre {
    border-radius: 0px !important;
}

a.list-group-item, button.list-group-item {
    color: #e0e0e0; /* 다크모드 텍스트 색상 */
    background-color: #121212; /* 다크모드 배경색 */
    border: 1px solid #444; /* 다크모드 테두리 색상 */
}

a.list-group-item:hover, button.list-group-item:hover {
    color: #ffffff; /* 다크모드 호버 시 텍스트 색상 */
    background-color: #444; /* 다크모드 호버 시 배경색 */
}

a.list-group-item.active, button.list-group-item.active {
    color: #ffffff; /* 다크모드 활성 상태 텍스트 색상 */
    background-color: #555; /* 다크모드 활성 상태 배경색 */
    border-color: #666; /* 다크모드 활성 상태 테두리 색상 */
}

a.list-group-item.active:hover {
    background-color: #555; /* 다크모드 활성 상태 배경색 */
    border-color: #666; /* 다크모드 활성 상태 테두리 색상 */
}

/* 스크롤바를 사용할 사이드바 */
/* 스크롤바 전체 영역 */
.sidebar::-webkit-scrollbar {
    background-color: #333; /* 스크롤바의 배경색 */
}

/* 스크롤바의 트랙 (스크롤바 배경) */
.sidebar::-webkit-scrollbar-track {
    background-color: #333; /* 다크모드 배경색 */
}

/* 스크롤바의 핸들 (사용자가 드래그할 부분) */
.sidebar::-webkit-scrollbar-thumb {
    background-color: #666; /* 다크모드 핸들 색상 */
    border-radius: 10px; /* 핸들의 둥근 모서리 */
}

/* 스크롤바의 핸들이 호버된 상태 */
.sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #888; /* 호버 시 핸들 색상 */
}

.toc-footer {
    background-color: #121212;
}

.toc-footer a {
    color: #fff; /* 링크 기본 색상 */
    text-decoration: none; /* 링크 밑줄 제거 */
}

.toc-footer a:hover,
.toc-footer a:focus {
    color: #fff; /* 링크 hover 또는 focus 시 색상 */
    text-decoration: underline; /* 링크 hover 또는 focus 시 밑줄 추가 */
}

/* .search-title */
.search-title {
    border-bottom: solid 1px #444; /* 어두운 테두리 색상 */
    padding-bottom: 10px;
}

/* .card */
.card {
    padding: 20px;
    background-color: #333; /* 카드 배경색 */
    border: solid 1px #444; /* 카드 테두리 색상 */
}

/* .search em */
.search em {
    background-color: #555; /* 다크모드 배경색 */
    color: #f0f0f0; /* 다크모드 텍스트 색상 */
    font-style: normal; /* 기울임체 제거 */
}

/* .no-content */
.no-content {
    background-color: #222; /* 다크모드 배경색 */
    border: 1px dashed #555; /* 다크모드 점선 테두리 색상 */
    padding: 10px;
    text-align: center; /* 텍스트 중앙 정렬 */
    font-style: italic; /* 기울임체 적용 */
    color: #e0e0e0; /* 다크모드 텍스트 색상 */
}

/* .subtitle */
.subtitle {
    border-bottom: solid 1px #555; /* 어두운 테두리 색상 */
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: var(--base-text-weight-semibold, 600);
    color: #e0e0e0; /* 다크모드 텍스트 색상 */
}

/* .find-item + .find-item */
.find-item + .find-item {
    margin-top: 15px; /* 원하는 마진 값 설정 */
}

/* .find-title */
.find-title {
    font-size: 16px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: var(--base-text-weight-semibold, 600);
    color: #e0e0e0; /* 다크모드 텍스트 색상 */
}

/* .find-type */
.find-type {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
    color: #e0e0e0; /* 다크모드 텍스트 색상 */
}

/* .find-content */
.find-content {
    white-space: pre-line;
    padding: 10px;
    background: #222; /* 다크모드 배경색 */
    color: #e0e0e0; /* 다크모드 텍스트 색상 */
}

/* .find-success */
.find-success {
    margin: 20px 0;
}

/* .card-header */
.card-header {
    background-color: #444; /* 다크모드 카드 헤더 배경색 */
    color: #e0e0e0; /* 다크모드 카드 헤더 텍스트 색상 */
}

/* .card-header:first-child */
.card-header:first-child {
    border-radius: 0; /* 모서리의 radius를 0으로 설정 */
}

/* .pagination */
.pagination {
    margin: 0;
    color: #e0e0e0; /* 다크모드 텍스트 색상 */
}

/* .pagination */
.pagination {
    font-size: 11px;
}

/* .pagination a */
.pagination a {
    text-decoration: none;
    border: solid 1px #555; /* 어두운 테두리 색상 */
    color: #ddd; /* 다크모드에서 링크 색상 */
}

/* .pagination a, .pagination span */
.pagination a, .pagination span {
    display: block;
    float: left;
    padding: 0.3em 0.5em;
    margin-right: 5px;
    margin-bottom: 5px;
    min-width: 1em;
    text-align: center;
    background: #333; /* 다크모드 배경색 */
    color: #ddd; /* 다크모드 텍스트 색상 */
}

/* .pagination .current */
.pagination .current {
    background: #555; /* 다크모드 배경색 */
    color: #fff; /* 다크모드 텍스트 색상 */
    border: solid 1px #444; /* 어두운 테두리 색상 */
}

/* .pagination .current.prev, .pagination .current.next */
.pagination .current.prev, .pagination .current.next {
    color: #aaa; /* 다크모드 텍스트 색상 */
    border-color: #aaa; /* 다크모드 테두리 색상 */
    background: #222; /* 다크모드 배경색 */
}

/* .revision */
.revision {
    white-space: pre-wrap;
    display: block;
    font-family: monospace;
    font-size: 12px;
    padding: 10px;
    border: solid 1px #555; /* 어두운 테두리 색상 */
    background-color: #333; /* 다크모드 배경색 */
    color: #ddd; /* 다크모드 텍스트 색상 */
    word-wrap: break-word;
    width: 100%;
}

/* .revision_header */
.revision_header {
    background: #444; /* 다크모드 배경색 */
    padding: 5px;
    margin: 10px 0;
    font-size: 12px;
    color: #ddd; /* 다크모드 텍스트 색상 */
}

/* .reversion_button */
.reversion_button {
    margin: 15px 0;
}

/* .revision-status-insert */
.revision-status-insert {
    background: #555; /* 어두운 배경색 */
    padding: 3px 5px;
    margin-left: 5px;
    color: #aaffaa; /* 다크모드 텍스트 색상 */
}

/* .revision-status-delete */
.revision-status-delete {
    background: #555; /* 어두운 배경색 */
    padding: 3px;
    margin-left: 5px;
    color: #f88; /* 다크모드 텍스트 색상 */
}

/* .revision_no */
.revision_no {
    font-size: 32px;
    font-weight: bold;
    color: #888; /* 다크모드 텍스트 색상 */
    margin: 0 5px;
    vertical-align: middle;
}

/* .revision_arrow */
.revision_arrow {
    color: #888; /* 다크모드 텍스트 색상 */
    font-size: 18px;
    vertical-align: middle;
}

/* .revision_arrow:hover */
.revision_arrow:hover {
    cursor: pointer;
    color: #ddd; /* 다크모드에서 호버 시 색상 */
}

/* .revision_insert */
.revision_insert {
    background-color: #555; /* 어두운 배경색 */
    color: #aaffaa; /* 다크모드 텍스트 색상 */
}

/* .revision_delete */
.revision_delete {
    background-color: #555; /* 어두운 배경색 */
    color: #f88; /* 다크모드 텍스트 색상 */
    text-decoration: line-through;
}

/* .revision_delete_block */
.revision_delete_block {
    background-color: #333; /* 어두운 배경색 */
}

/* .revision_insert_block */
.revision_insert_block {
    background-color: #333; /* 어두운 배경색 */
}

/* .revision_delete_block .revision_insert */
.revision_delete_block .revision_insert {
    background-color: #333; /* 어두운 배경색 */
    color: #333; /* 다크모드 텍스트 색상 */
}

/* .revision_insert_block .revision_delete */
.revision_insert_block .revision_delete {
    background-color: #333; /* 어두운 배경색 */
    color: #333; /* 다크모드 텍스트 색상 */
}

.popover.left .arrow::after {
    border-left-color: #333; /* 왼쪽에 위치한 화살표 내부 색상 */
}
.popover.top .arrow::after {
    border-top-color: #333; /* 위쪽에 위치한 화살표 내부 색상 */
}
.popover.bottom .arrow::after {
    border-bottom-color: #444; /* 아래쪽에 위치한 화살표 내부 색상 */
}
.page img {
    margin-top: 10px;
    margin-bottom: 10px;
}

.hljs-comment, .hljs-quote {
    color: #8a8f9a !important;
    font-style: italic;
}